<template>
  <v-dialog
    v-model="dialogTicket"
    @click:outside="fecharDialog"
    @keydown.esc="fecharDialog"
    width="450px"
  >
    <v-card>
      <v-card-title class="px-6 toolbar">
        <span class="white--text"> Deseja imprimir? </span>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            @click="pdfTicket"
            :loading="loading"
            color="tocs_gray_1"
            class="vertical-button mt-4"
            small
          >
            <v-icon class="pt-2" size="35" color="tocs_gray_2"
              >mdi-ticket-confirmation-outline</v-icon
            >
            <span class="pb-2 font-weight-bold"> Ticket </span>
          </v-btn>
          <v-btn
            v-if="possui_contrato"
            @click="openContrato"
            :loading="loading_contrato"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2"
                  >mdi-file-sign</v-icon
                >
              </div>
              <div>
                <span class="pb-2 font-weight-bold">
                  {{ $tc("global.contrato") }}
                </span>
              </div>
            </div>
          </v-btn>
        </div>
      </v-card-text>
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        @close="loading = false"
        title="PDF"
      />
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" small class="white--text" color="button_2">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getContrato } from "@/api/contratos/contratos.js";

const importTicket = () =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_ticket.js");

const pdfContratoGenerator = () =>
  import(
    /* webpackChunkName: "venda-pdf" */ "@/views/dashboard/contratos/reports/contrato_pdf.js"
  );
const pdfContratoGeneratorFiador = () =>
  import(
    /* webpackChunkName: "venda-pdf" */ "@/views/dashboard/contratos/reports/contrato_pdf_fiador.js"
  );
  
export default {
  name: "DialogTicket",
  props: {
    dialogTicket: {
      type: Boolean,
      default: false,
    },
    venda_id: {
      type: [Number],
    },
    possui_contrato: {
      type: Boolean,
      default: false,
    },
    possui_fiador: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading: false,
      loading_contrato: false,
      pdfDialog: false,
      pdfData: {},
    };
  },

  computed: {
    sizeButtomTransf() {
      return this.$vuetify.lang.current === "en" ? false : true;
    },
  },

  methods: {
    fecharDialog() {
      this.$emit("fechar-dialog");
    },
    async pdfTicket() {
      this.loading = true;

      try {
        importTicket()
          .then((module) => {
            let generate = module.default;
            generate(this.venda_id, false, false).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async openContrato() {
      try {
        this.pdfContrato = true;
        this.loading_contrato = true;
        const contrato = await getContrato(this.venda_id);
        if (contrato.fiador_nome){
          await pdfContratoGeneratorFiador()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.venda_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading_contrato = false;
          });
        }
        else{
          await pdfContratoGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.venda_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading_contrato = false;
          });
        }
      } catch (error) {
        this.loading_contrato = false;
      }
    },
  },

  mounted() {},
};
</script>

<style>
.vertical-button,
.vertical-button span.v-btn__content {
  height: 80px !important;
  width: 90px !important;
}
.vertical-button span.v-btn__content {
  flex-direction: column;
  justify-content: space-around;
}
</style>
